
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Zhou Wenye
   * Date: 12th of August 2021
   *
   * Summary: Edit Company Modal Component
   */
  import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
  import { ValidationObserver } from 'vee-validate';
  import ASCXCompanyProfileEdit from '@rdss-mono/ascx-company-profile-edit';
  import {
    AccountData,
    AccountConfigData
  } from '@/components/dashboards/my-company-dashboard/MyCompanyDashboard.vue';
  import { cloneDeep } from 'lodash';

  @Component({
    components: {
      ValidationObserver,
      ASCXCompanyProfileEdit
    }
  })
  export default class EditCompanyModal extends Vue {
    @Prop({ required: true, type: String })
    readonly name: string;

    @Prop({ required: true, type: String, default: '' })
    readonly apiError: string;

    @Prop({ required: true, type: Object as () => AccountData })
    readonly company: AccountData;

    @Prop({ required: true, type: Array, default: () => [] })
    readonly contacts: Array<AccountPrimaryContact>;

    phoneValid = true;
    companyConfig = null;

    translate(key: string): string {
      return this.$t(key).toString();
    }
    @Emit('edit-company')
    save(): AccountConfigData | null {
      Object.getOwnPropertyNames(this.companyConfig).forEach((key) => {
        if (
          this.companyConfig[key] &&
          typeof this.companyConfig[key] === 'object'
        ) {
          Object.getOwnPropertyNames(this.companyConfig[key]).forEach(
            (value) => {
              if (this.companyConfig[key][value] === null) {
                delete this.companyConfig[key][value];
              }
            }
          );
        } else {
          if (this.companyConfig[key] === null) {
            delete this.companyConfig[key];
          }
        }
      });
      return this.companyConfig;
    }

    @Emit('clear-error')
    resetForm(): void {
      this.companyConfig = cloneDeep(this.company.config);
    }
    created(): void {
      this.resetForm();
    }
    /**
     * When the phone number is not empty but does not meet the number specification
     * change the flag to make save button disabled
     * @return void
     */
    invalidPhone(data: boolean): void {
      this.phoneValid = data;
    }
  }

  interface AccountPrimaryContact {
    name: string;
    email_address: string;
    language: string;
    mfa_method: string;
    mobile_phone: string;
    image_url?: string;
    role: string;
  }
