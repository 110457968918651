
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Guillermo Gonzalez
   * Date: 10, August 2021
   *
   * Summary: My Company Dashboard
   */
  import { Component, Vue } from 'vue-property-decorator';
  import AscxCompanyProfile from '@rdss-mono/ascx-company-profile';
  import EditCompanyModal from '@/components/modals/edit-company/EditCompanyModal.vue';
  import MessageBoxModal from '@/components/modals/message-box-modal/MessageBoxModal.vue';
  import { Action } from 'vuex-class';
  import { getErrorMessage } from '@/utils/Helpers';

  @Component({
    components: {
      AscxCompanyProfile,
      EditCompanyModal,
      MessageBoxModal
    }
  })
  export default class MyCompanyDashboard extends Vue {
    readonly editIcon = require('./assets/images/edit.svg');
    editMyCompanyError = '';
    contacts = [];
    editDisabled = true;
    @Action privateContract;
    @Action updateCompany;
    @Action getAccount;
    private boxModalMessage = {
      message: '',
      status: '',
      buttonText: '',
      titleTextVariant: ''
    };

    async mounted(): Promise<void> {
      const loader = this.$loading.show();
      try {
        const response = await this.$http.get(
          `/${this.$store.getters.user.account_uuid}`
        );
        this.$store.commit('updateAccount', response.data.detailed_data);
        this.contacts = await this.privateContract();
        this.editDisabled = false;
        loader.hide();
      } catch (e) {
        if (
          e.response.data.status_msg_key ===
          'rest_api.response.error.ACME_ACCOUNT_NOT_FOUND'
        ) {
          this.boxModalMessage.message = getErrorMessage(e);
          this.boxModalMessage.status = 'label.acme_account_not_found';
          this.boxModalMessage.buttonText = 'button.close';
          this.boxModalMessage.titleTextVariant = 'text-danger';
          this.$bvModal.show(this.messageBoxModalId);
        }
        this.editDisabled = true;
        loader.hide();
      }
    }

    get account(): AccountData {
      const { account } = this.$store.getters;

      account.config.phone = !account.config.phone ? '' : account.config.phone;
      account.config.emergency_phone = !account.config.emergency_phone
        ? ''
        : account.config.emergency_phone;

      return account;
    }

    private get editCompanyModal(): string {
      return 'editCompany';
    }

    private get messageBoxModalId(): string {
      return 'my-company-dashboard-messageBoxModal';
    }

    private resetMessageBox(): void {
      this.$bvModal.hide(this.messageBoxModalId);
    }

    private resetApiError() {
      this.editMyCompanyError = '';
    }

    private translate(key: string): string {
      return this.$t(key).toString();
    }

    private async edit(company: AccountData): Promise<void> {
      try {
        await this.updateCompany({
          accountId: this.$store.getters.user.account_uuid,
          data: company
        });
        await this.getAccount({
          accountId: this.$store.getters.user.account_uuid
        });
        this.$bvModal.hide(this.editCompanyModal);
      } catch (err) {
        if (
          err.response.data.status_msg_key ===
          'rest_api.response.error.ACME_ACCOUNT_NOT_FOUND'
        ) {
          this.boxModalMessage.message = getErrorMessage(err);
          this.boxModalMessage.status = 'label.acme_account_not_found';
          this.boxModalMessage.buttonText = 'button.close';
          this.boxModalMessage.titleTextVariant = 'text-danger';
          this.$bvModal.show(this.messageBoxModalId);
        } else {
          this.editMyCompanyError = err.response.data.status_msg_key
            ? `${err.response.data.status_msg_key}`
            : `${err.response.data.status_message}`;
        }
      }
    }
  }

  export interface AccountData {
    account_id: string;
    account_uuid: string;
    status: string;
    config: AccountConfigData;
  }

  export interface AccountConfigData {
    name: string;
    address: string;
    phone: string;
    logo_url?: string;
    website_url?: string;
    info_email_address: string;
    primary_contact: AccountPrimaryContact;
  }

  export interface AccountPrimaryContact {
    full_name: string;
  }
